import React, {useEffect, useState} from 'react';
import {Dropdown, Message} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {updateLocalExperienceValue} from "../../../../redux/experienceDucks";

export const ExperienceFormTopics = () => {
    const experienceTopics = useSelector(state => state.experiences.experience.value.topics);

    const topics = useSelector(state => state.topics.topics);

    const [topicItems, setTopicItems] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!topics) {
            return;
        }
        setTopicItems(topics.map(topic => {
            return {
                key: topic.id,
                text: topic.name,
                value: topic.name
            }
        }))
    }, [topics]);

    const handleChange = async (event, data) => {
        await experienceTopics.setValue(data.value);
        dispatch(updateLocalExperienceValue({topics: experienceTopics}));
    };

    return (
        <>
            {
                experienceTopics.hasError() &&
                <Message error visible>
                    {experienceTopics.error}
                </Message>
            }
            <Dropdown
                placeholder='Select topics'
                fluid
                multiple
                onChange={handleChange}
                options={topicItems}
                search
                selection
                value={experienceTopics.value}
            />
        </>
    )
};
