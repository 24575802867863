export function fromFileUrlToBase64(url) {
    return new Promise((resolve, reject) => {
        const httpRequest = new XMLHttpRequest();
        httpRequest.onload = () => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = btoa(reader.result);
                resolve(`data:image/png;base64,${base64}`);
            }
            reader.readAsBinaryString(httpRequest.response);
        };
        httpRequest.open('GET', url);
        httpRequest.responseType = 'blob';
        httpRequest.send();
    });
}
