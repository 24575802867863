import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Button, Card} from 'semantic-ui-react';
import {ReactComponent as AddExperienceIcon} from '../../../../assets/Icons/add-experience.svg';
import * as ROUTES from '../../../../constants/routes';

const ExperienceCardItemAdd = () => {
    return (
        <Card
            style={{
                height: '256px',
                width: '212px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(132, 132, 132, 0.4)',
            }}
        >
            <div>
                <Button
                    icon
                    circular
                    style={{
                        width: '48px',
                        backgroundColor: '#1b8f45',
                        color: 'white',
                        borderRadius: '10em',
                    }}
                    as={Link} to={ROUTES.EXPERIENCE_CREATE}
                >
                    <AddExperienceIcon/>
                </Button>
            </div>
        </Card>
    );
};

export default withRouter(ExperienceCardItemAdd);
