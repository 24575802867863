import React from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Card, Image} from 'semantic-ui-react';
import {ReactComponent as InfoExperienceIcon} from '../../../../assets/Icons/info-experience.svg';
import {EXPERIENCE_UPDATE} from "../../../../constants/routes";
import {ExperienceState} from "../../../../v2/experience/state";

const ExperienceCardItem = props => {

    const {experience} = props;

    function handleCardClick(experienceId) {
        // Parse the route to the experience view
        const route = EXPERIENCE_UPDATE.replace(':id', experienceId)
        // Open the view for editing the experience
        props.history.push(route);
    }

    function isExperienceInReadyForReview() {
        return experience.value.status.value.state.value === ExperienceState.READY_FOR_REVIEW;
    }

    return (
        <Card
            onClick={handleCardClick.bind(this, experience.value.id.value)}
            raised
            style={{
                width: '212px',
                height: '256px',
            }}
        >
            <div
                className={"image"}
                style={{
                    height: '60%',
                }}
            >
                <Image
                    src={experience.value.images.value[0]?.pictureUrl ?? null}
                    style={{
                        objectFit: 'cover',
                        backgroundPosition: 'center',
                        height: '100%',
                        width: '100%',
                    }}
                />
                {isExperienceInReadyForReview() &&
                    <Button
                        circular
                        icon
                        labelPosition='right'
                        style={{
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)',
                            top: '50%',
                            left: '50%',
                            width: '75%',
                            height: '40px',
                            backgroundColor: '#e54e26',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: 'white',
                        }}
                    >
                        In Review
                        <i aria-hidden='true'
                           className='icon right'
                           style={{
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                               backgroundColor: 'transparent',
                           }}
                        >
                            <InfoExperienceIcon style={{height: '40%'}}/>
                        </i>
                    </Button>
                }
            </div>
            <Card.Content>
                <Card.Header style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
                >
                    {experience.value.contact.value.name.value}
                </Card.Header>
                <Card.Description style={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                }}>
                    {experience.value.destination.value.name.value}
                </Card.Description>
            </Card.Content>
        </Card>
    );
};

export default withRouter(ExperienceCardItem);
