import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Grid} from 'semantic-ui-react';
import AppBase from '../../components/AppBase/AppBase';
import UserProfileImage from './UserProfileImage';
import UserProfileInfo from './UserProfileInfo';
import UserProfileImagePick from './UserProfileImage/UserProfileImageMessage';
import {ReactComponent as SaveIcon} from '../../assets/Icons/check-experience.svg';
import {getProfile, updateProfile} from '../../redux/profileDucks';
import {signOut} from "../../redux/userDucks";

const UserProfile = props => {

    const storeUserProfile = useSelector(store => store.profile.profile);
    const [userError, setUserError] = useState({});
    const [openImage, setOpenImage] = useState(false);
    const initialUserProfile = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        avatar: '',
        avatarBase64: '',
    };
    const [stateUserProfile, setStateUserProfile] = useState({...initialUserProfile});
    const [profileChanged, setProfileChanged] = useState(false);
    const dispatch = useDispatch();

    const onChangeData = event => {
        const {name, value} = event.target;
        setStateUserProfile({...stateUserProfile, [name]: value});
    };

    const onChangeBusiness = event => {
        const {name, value} = event.target;
        setStateUserProfile({...stateUserProfile, business: {...stateUserProfile.business, [name]: value}});
    };

    const onImageChange = file => {
        if (!!file) {
            setStateUserProfile({...stateUserProfile, ...file});
            setOpenImage(false);
        }
    };

    const onSubmit = event => {
        event.preventDefault();
        const profileErrors = validateUserProfile(stateUserProfile);
        setUserError(profileErrors);
        if (Object.entries(profileErrors).length === 0 && profileChanged) {
            dispatch(updateProfile(stateUserProfile));
        }
    };

    const validateUserProfile = profile => {
        const errors = {};
        const onlyLetters = /^[a-zñáéíóúüñ]*$/i;

        if (profile.firstName === null || profile.firstName.length === 0 ||
            /^\s+$/.test(profile.firstName)) {
            errors.firstName = "Please enter a first name";
        } else if (!profile.firstName.replace(" ", "").match(onlyLetters)) {
            errors.firstName = "Please enter letters only";
        }

        if (profile.lastName === null || profile.lastName.length === 0 ||
            /^\s+$/.test(profile.lastName)) {
            errors.lastName = "Please enter a last name";
        } else if (!profile.lastName.replace(" ", "").match(onlyLetters)) {
            errors.lastName = "Please enter letters only";
        }

        if (profile.phoneNumber !== null && profile.phoneNumber.length !== 0) {
            if (isNaN(parseInt(profile.phoneNumber))) {
                errors.phoneNumber = "Please enter numbers only"
            }
            if (profile.phoneNumber.length !== 8) {
                errors.phoneNumber = "Please enter an 8-digit phone number"
            }
        }

        return errors;
    };

    const isUserProfileDifferentFromStore = (profile, profileRedux) => {
        return profile.firstName !== profileRedux.firstName ||
            profile.lastName !== profileRedux.lastName ||
            profile.phoneNumber !== profileRedux.phoneNumber ||
            profile.avatarBase64 !== '';
    };

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    useEffect(() => {
        setStateUserProfile({
            ...initialUserProfile,
            ...storeUserProfile,
        });
    }, [storeUserProfile]);

    useEffect(() => {
        const isChange = isUserProfileDifferentFromStore(stateUserProfile, storeUserProfile);
        setProfileChanged(isChange);
    }, [stateUserProfile]);

    function handleSignOutClick() {
        dispatch(signOut());
    }

    return (<>
        <UserProfileImagePick
            open={openImage}
            userProfile={stateUserProfile}
            handleOnImageChange={onImageChange}
            handleCloseMessage={setOpenImage.bind(this, false)}
        />
        <AppBase style={{
            filter: `${(openImage) ? 'brightness(.6)' : 'brightness(1)'}`,
        }}>
            <Grid
                centered
                style={{
                    margin: '0px',
                }}
            >
                <Grid.Column
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50%',
                    }}
                >
                    <Form onSubmit={onSubmit}>
                        <UserProfileImage
                            userProfile={stateUserProfile}
                            setOpenImage={setOpenImage.bind(this, true)}
                        />
                        <UserProfileInfo
                            userProfile={stateUserProfile}
                            handleUserError={userError}
                            handleOnChangeData={onChangeData}
                            handleOnChangeBusiness={onChangeBusiness}
                        />
                        <div
                            style={{
                                marginTop: '16px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                icon
                                labelPosition='right'
                                circular
                                style={{
                                    width: '250px',
                                    height: '60px',
                                    backgroundColor: '#1b8f45',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    lineHeight: '1.13',
                                    textAlign: 'center',
                                    color: '#ffffff',
                                }}
                                disabled={!profileChanged}
                            >
                                Save changes
                                <i
                                    aria-hidden='true'
                                    className='icon right'
                                    style={{
                                        backgroundColor: "transparent",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <SaveIcon/>
                                </i>
                            </Button>
                        </div>
                        <div
                            style={{
                                marginTop: '16px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                onClick={handleSignOutClick}
                                circular
                                style={{
                                    width: '250px',
                                    height: '60px',
                                    backgroundColor: '#D75B31',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    lineHeight: '1.13',
                                    textAlign: 'center',
                                    color: '#ffffff',
                                }}
                            >
                                Sign out
                            </Button>
                        </div>
                    </Form>
                </Grid.Column>
            </Grid>
        </AppBase>
    </>);
};

export default withRouter(UserProfile);
