import React from 'react';
import {Grid, Header} from 'semantic-ui-react';
import FormInput from '../../../components/FormInput';
import {ReactComponent as UserCompleteIcon} from '../../../assets/Icons/user-complete.svg';
import {ReactComponent as EmailEmptyIcon} from '../../../assets/Icons/email-empty.svg';
import {ReactComponent as PhoneEmptyIcon} from '../../../assets/Icons/phone-empty.svg';

const UserProfileInfo = ({
                             userProfile,
                             handleOnChangeData,
                             handleUserError
                         }) => {
    return (<>
        <Grid
            columns={1}
            centered
        >
            <Grid.Column
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '4% 2%'
                }}
            >
                <Header
                    style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: '1.29',
                        letterSpacing: '0.5px',
                        color: '#000000',
                        width: '320px',
                    }}
                >
                    CONTACT
                </Header>
                <FormInput
                    name='firstName'
                    value={userProfile.firstName}
                    placeholder='First Name'
                    type='text'
                    IconInput={UserCompleteIcon}
                    handleOnChange={handleOnChangeData.bind(this)}
                    error={handleUserError.firstName}
                    style={{
                        width: '320px'
                    }}
                />
                <FormInput
                    name='lastName'
                    value={userProfile.lastName}
                    placeholder='Last Name'
                    type='text'
                    IconInput={UserCompleteIcon}
                    handleOnChange={handleOnChangeData.bind(this)}
                    error={handleUserError.lastName}
                    style={{
                        width: '320px'
                    }}
                />
                <FormInput
                    name='email'
                    value={userProfile.email}
                    placeholder='Email'
                    type='text'
                    IconInput={EmailEmptyIcon}
                    handleOnChange={() => undefined}
                    style={{
                        width: '320px'
                    }}
                    disabled={true}
                />
                <FormInput
                    name='phoneNumber'
                    value={userProfile.phoneNumber}
                    placeholder='Telephone'
                    type='text'
                    IconInput={PhoneEmptyIcon}
                    handleOnChange={handleOnChangeData.bind(this)}
                    error={handleUserError.phoneNumber}
                    style={{
                        width: '320px'
                    }}
                />
            </Grid.Column>
        </Grid></>);
};

export default UserProfileInfo;
