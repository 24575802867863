import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import userReducer, {getUserFromLocalStorage} from './userDucks';
import experienceReducer from './experienceDucks';
import profileReducer from './profileDucks';
import creditCardReducer from './creditCardDucks';
import appReducer from './appDucks';
import topicReducer, {getTopics} from './topicDucks';
import destinationReducer, {getDestinations} from './destinationDucks';

const STORE_RESET_STORE = 'STORE_RESET_STORE';

export const createRootReducer = (state, action) => {
    if (action.type === STORE_RESET_STORE) {
        // To invalidate the local session.
        localStorage.clear();
        // To preserve static data.
        state = {
            destinations: state.destinations,
            topics: state.topics,
        }
    }
    return combineReducers({
        app: appReducer,
        authentication: userReducer,
        creditCards: creditCardReducer,
        destinations: destinationReducer,
        experiences: experienceReducer,
        profile: profileReducer,
        topics: topicReducer
    })(state, action);
};

export const resetStore = () => ({
    type: STORE_RESET_STORE
});


let store = null;

export function getState() {
    if (!store) {
        throw new Error('Store not initialized');
    }
    return store.getState();
}

export default function setUpStore(preloadedState = {}) {
    store = createStore(createRootReducer, preloadedState, compose(applyMiddleware(thunk)));
    store.dispatch(getUserFromLocalStorage());
    store.dispatch(getTopics());
    store.dispatch(getDestinations());
    return store;
};
