import React from 'react';
import Accommodation from '../../assets/Icons/next/accommodation.png';
import AdultsOnly from '../../assets/Icons/next/adults-only.png';
import AllTerrainVehiclesOnly from '../../assets/Icons/next/all-terrain-vehicles-only.png';
import AmericanExpress from '../../assets/Icons/next/american-express.png';
import BankTransfer from '../../assets/Icons/next/bank-transfer.png';
import BirdWatching from '../../assets/Icons/next/bird-watching.png';
import Bitcoin from '../../assets/Icons/next/bitcoin.png';
import Camping from '../../assets/Icons/next/camping.png';
import CarbonNeutral from '../../assets/Icons/next/carbon-neutral.png';
import Cash from '../../assets/Icons/next/cash.png';
import EvCharger from '../../assets/Icons/next/ev-charger.png';
import FoodDrink from '../../assets/Icons/next/food-drink.png';
import Internet from '../../assets/Icons/next/internet.png';
import MasterCard from '../../assets/Icons/next/master-card.png';
import NoParking from '../../assets/Icons/next/no-parking.png';
import NotPetFriendly from '../../assets/Icons/next/not-pet-friendly.png';
import NotWheelchairAccessible from '../../assets/Icons/next/not-wheelchair-accessible.png';
import Parking from '../../assets/Icons/next/parking.png';
import PayPal from '../../assets/Icons/next/paypal.png';
import PetFriendly from '../../assets/Icons/next/pet-friendly.png';
import PublicTransport from '../../assets/Icons/next/public-transport.png';
import SecurityLockers from '../../assets/Icons/next/security-lockers.png';
import Showers from '../../assets/Icons/next/showers.png';
import SinpeMovil from '../../assets/Icons/next/sinpe-movil.png';
import SmokingArea from '../../assets/Icons/next/smoking-area.png';
import Toilets from '../../assets/Icons/next/toilets.png';
import Visa from '../../assets/Icons/next/visa.png';
import WheelchairAccessible from '../../assets/Icons/next/weelchair-accessible.png';

export const NextIcon = ({name, size}) => {
    // A dictionary for mapping names to icons.
    const icons = {
        accommodation: Accommodation,
        adultsOnly: AdultsOnly,
        allTerrainVehicleOnly: AllTerrainVehiclesOnly,
        americanExpress: AmericanExpress,
        bankTransfer: BankTransfer,
        birdWatching: BirdWatching,
        bitcoin: Bitcoin,
        camping: Camping,
        carbonNeutral: CarbonNeutral,
        cash: Cash,
        evCharger: EvCharger,
        foodDrink: FoodDrink,
        internet: Internet,
        masterCard: MasterCard,
        noParking: NoParking,
        notPetFriendly: NotPetFriendly,
        notWheelchairAccessible: NotWheelchairAccessible,
        parking: Parking,
        payPal: PayPal,
        petFriendly: PetFriendly,
        publicTransport: PublicTransport,
        securityLockers: SecurityLockers,
        showers: Showers,
        sinpeMovil: SinpeMovil,
        smokingArea: SmokingArea,
        toilets: Toilets,
        visa: Visa,
        wheelchairAccessible: WheelchairAccessible,
    }

    // A dictionary for mapping sizes to actual sizes.
    const sizes = {
        'small': '32px',
        'medium': '64px',
        'large': '96px',
    }

    return <div style={{display: 'flex', justifyContent: 'center'}}>
        <img src={icons[name]} style={{width: sizes[size], height: sizes[size]}}/>
    </div>
}
