import React, {useEffect, useState} from 'react'
import {Form, Label} from "semantic-ui-react";
import {updateLocalExperienceValue} from "../../../../redux/experienceDucks";
import {useDispatch, useSelector} from "react-redux";

export const ExperienceFormContact = () => {
    const contact = useSelector(state => state.experiences.experience.value.contact);

    const [nameText, setNameText] = useState('');
    const [emailText, setEmailText] = useState('');
    const [telephoneText, setTelephoneText] = useState('');
    const [whatsAppText, setWhatsAppText] = useState('');
    const [websiteText, setWebsiteText] = useState('');
    const [facebookText, setFacebookText] = useState('');
    const [instagramText, setInstagramText] = useState('');

    useEffect(() => {
        if (!contact) {
            return;
        }
        setNameText(contact.value.name.value);
        setEmailText(contact.value.email.value);
        setTelephoneText(contact.value.phoneNumber.value);
        setWhatsAppText(contact.value.whatsApp.value);
        setWebsiteText(contact.value.websiteUrl.value);
        setFacebookText(contact.value.facebookUrl.value);
        setInstagramText(contact.value.instagramUrl.value);
    });

    const dispatch = useDispatch();

    const handleContactChange = async (patch) => {
        patch = await contact.setValue({...contact.value, ...patch});
        await dispatch(updateLocalExperienceValue({patch}));
    }

    const handleNameChange = async (event) => {
        setNameText(event.target.value);
        const name = await contact.value.name.setValue(event.target.value);
        await handleContactChange({name});
    }

    const handleEmailChange = async (event) => {
        setEmailText(event.target.value);
        const email = await contact.value.email.setValue(event.target.value);
        await handleContactChange({email});
    }

    const handleTelephoneChange = async (event) => {
        setTelephoneText(event.target.value);
        const phoneNumber = await contact.value.phoneNumber.setValue(event.target.value);
        await handleContactChange({phoneNumber});
    }

    const handleWhatsAppChange = async (event) => {
        setWhatsAppText(event.target.value);
        const whatsApp = await contact.value.whatsApp.setValue(event.target.value);
        await handleContactChange({whatsApp});
    }

    const handleWebsiteChange = async (event) => {
        setWebsiteText(event.target.value);
        const websiteUrl = await contact.value.websiteUrl.setValue(event.target.value);
        await handleContactChange({websiteUrl});
    }

    const handleFacebookChange = async (event) => {
        setFacebookText(event.target.value);
        const facebookUrl = await contact.value.facebookUrl.setValue(event.target.value);
        await handleContactChange({facebookUrl});
    }

    const handleInstagramChange = async (event) => {
        setInstagramText(event.target.value);
        const instagramUrl = await contact.value.instagramUrl.setValue(event.target.value);
        await handleContactChange({instagramUrl});
    }

    return (
        <>
            <Form.Field
                error={contact.value.name.hasError()}
            >
                <label>Name</label>
                <input
                    placeholder={'OneTwoTrail'}
                    onChange={handleNameChange}
                    value={nameText}
                />
                {
                    contact.value.name.hasError() && <Label pointing prompt>
                        {contact.value.name.error}
                    </Label>
                }
            </Form.Field>
            <Form.Field
                error={contact.value.email.hasError()}
            >
                <label>Email</label>
                <input
                    placeholder={'contact@onetwotrail.com'}
                    onChange={handleEmailChange}
                    value={emailText}
                />
                {
                    contact.value.email.hasError() && <Label pointing prompt>
                        {contact.value.email.error}
                    </Label>
                }
            </Form.Field>
            <Form.Field
                error={contact.value.phoneNumber.hasError()}
            >
                <label>Telephone</label>
                <input
                    placeholder={'85034429'}
                    onChange={handleTelephoneChange}
                    value={telephoneText}
                />
                {
                    contact.value.phoneNumber.hasError() && <Label pointing prompt>
                        {contact.value.phoneNumber.error}
                    </Label>
                }
            </Form.Field>
            <Form.Field
                error={contact.value.whatsApp.hasError()}
            >
                <label>WhatsApp</label>
                <input
                    placeholder={'85034429'}
                    onChange={handleWhatsAppChange}
                    value={whatsAppText}
                />
                {
                    contact.value.whatsApp.hasError() && <Label pointing prompt>
                        {contact.value.whatsApp.error}
                    </Label>
                }
            </Form.Field>
            <Form.Field
                error={contact.value.websiteUrl.hasError()}
            >
                <label>Website</label>
                <input
                    placeholder={'https://onetwotrail.com'}
                    onChange={handleWebsiteChange}
                    value={websiteText}
                />
                {
                    contact.value.websiteUrl.hasError() && <Label pointing prompt>
                        {contact.value.websiteUrl.error}
                    </Label>
                }
            </Form.Field>
            <Form.Field
                error={contact.value.facebookUrl.hasError()}
            >
                <label>Facebook</label>
                <input
                    placeholder={'https://facebook.com/onetwotrailcr'}
                    onChange={handleFacebookChange}
                    value={facebookText}
                />
                {
                    contact.value.facebookUrl.hasError() && <Label pointing prompt>
                        {contact.value.facebookUrl.error}
                    </Label>
                }
            </Form.Field>
            <Form.Field
                error={contact.value.instagramUrl.hasError()}
            >
                <label>Instagram</label>
                <input
                    placeholder={'https://instagram.com/onetwotrail'}
                    onChange={handleInstagramChange}
                    value={instagramText}
                />
                {
                    contact.value.instagramUrl.hasError() && <Label pointing prompt>
                        {contact.value.instagramUrl.error}
                    </Label>
                }
            </Form.Field>
        </>
    )
}
