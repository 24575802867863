export const ROOT = '/';

export const CONSOLE = '/console';

export const CONSUMER_EMAIL_VERIFIED = '/verified';

export const EXPERIENCE_CREATE = '/experiences/create';
export const EXPERIENCE_DASHBOARD = '/experiences/:id/dashboard';
export const EXPERIENCE_RETRIEVE = '/experiences/:id';
export const EXPERIENCE_UPDATE = '/experiences/:id/edit';

export const PASSWORD_FORGOT = '/forgot-password';

export const LOGIN = '/login';
export const PROFILE = '/profile';
export const REGISTER = '/register';
