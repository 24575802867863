import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Segment} from 'semantic-ui-react';
import RegisterBase from './RegisterBase/RegisterBase';
import FormDataUser from './RegisterForm/FormDataUser/FormDataUser';
import FormPasswordUser from './RegisterForm/FormPasswordUser/FormPasswordUser';
import * as ROUTES from '../../constants/routes';
import {validatePassword, validateUserData, validateUserDataFormat} from './RegisterValidation';
import {incrementActiveStep, resetActiveStep, signUpWithEmail} from '../../redux/userDucks';
import {validatePasswordRequirements} from "../../v2/password";

const Register = props => {

    const activeStep = useSelector(store => store.authentication.activeStep);
    const isLogged = useSelector(store => store.authentication.isLogged);
    const error = useSelector(store => store.authentication.error);
    const [userError, setUserError] = useState({});
    const [userFieldsFormat, setUserFieldsFormat] = useState({});
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        terms: false
    });
    const dispatch = useDispatch();

    /**
     * This method is to track the variable isLogged to redirect the user
     */
    useEffect(() => {
        isLogged ? props.history.push(ROUTES.CONSOLE) : props.history.push(ROUTES.REGISTER);
    }, [isLogged, props.history]);

    const onSubmit = async event => {
        event.preventDefault();
        if (activeStep === 0) {
            const errorUser = validateUserData(user);
            setUserError(errorUser);
            if (Object.entries(errorUser).length === 0) {
                dispatch(incrementActiveStep());
            }
        } else if (activeStep === 1) {
            const passwordError = validatePassword(user);
            setUserError({passwordError});
            if (!passwordError) {
                await dispatch(signUpWithEmail(user));
                props.history.push(ROUTES.LOGIN + "?r=1");
            }
        }
    };

    const onChange = (event, data) => {
        if (data) {
            const {name, checked} = data;
            setUser({...user, [name]: checked});
        } else {
            const {name, value} = event.target;
            setUser({...user, [name]: value});
        }
    };

    useEffect(() => {
        if (activeStep === 0) {
            const isUserDataComplete = validateUserDataFormat(user);
            setUserFieldsFormat({isUserDataComplete});
        }
        if (activeStep === 1) {
            const passwordRequirements = validatePasswordRequirements(user.password,
                user.confirmPassword);
            if (Object.entries(passwordRequirements).length !== 0)
                setUserFieldsFormat(passwordRequirements);
        }
    }, [activeStep, user]);

    useEffect(() => {
        if (!isLogged) {
            dispatch(resetActiveStep());
        }
    }, [dispatch, isLogged]);

    useEffect(() => {
        setUserError({email: error});
    }, [error]);

    return (
        <RegisterBase>
            <Segment
                style={{
                    display: 'flex',
                    width: '430px',
                    minHeight: '334px',
                    height: '100%',
                    borderRadius: '20px',
                    backgroundColor: '#e4dcca',
                    boxShadow: '1px 2px 4px 0 rgba(0,0,0,0.1)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '10% 0',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '0px',
                    marginBottom: '0px',
                }}
            >
                {activeStep === 0 &&
                    <FormDataUser
                        user={user}
                        handleOnSubmit={onSubmit}
                        handleOnChange={onChange}
                        handleUserError={userError}
                    />
                } {activeStep === 1 &&
                <FormPasswordUser
                    user={user}
                    handleOnSubmit={onSubmit}
                    handleOnChange={onChange}
                    handleUserError={userError}
                    handleUserFieldsFormat={userFieldsFormat}
                />
            }
            </Segment>
        </RegisterBase>
    );
};

export default withRouter(Register);
