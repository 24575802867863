import React from 'react';
import {Divider, Form, Grid, Header, Message} from 'semantic-ui-react';
import {useSelector} from "react-redux";
import './ExperienceFormFields.css';
import {ExperienceFormLocation} from "../ExperienceFormLocation/ExperienceFormLocation";
import {ExperienceFormImages} from "../ExperienceFormImages/ExperienceFormImages";
import {ExperienceFormProducts} from "../ExperienceFormProducts/ExperienceFormProducts";
import {ExperienceFormContact} from "../ExperienceFormContact";
import {ExperienceFormPaymentMethods} from "../ExperienceFormPaymentMethods";
import {ExperienceFormFeatures} from "../ExperienceFormFeatures";
import {ExperienceFormSchedule} from "../ExperienceFormSchedule";
import {ExperienceFormTopics} from "../ExperienceFormTopics";
import {ExperienceFormDescription} from "../ExperienceFormDescription";
import {ExperienceFormDestination} from "../ExperienceFormDestination";
import ExperienceFormSubmitButton from "../ExperienceFormSubmitButton";


export const ExperienceFormFields = () => {
    // A selector to retrieve the user from the redux store.
    const user = useSelector(state => state.authentication.user);

    // A selector to retrieve the experience from the redux store.
    const experience = useSelector(state => state.experiences.experience);

    const handleFormSubmit = event => {
        // Prevent the default behavior of the form.
        event.preventDefault();
    };

    return (
        <Grid.Column>
            <Form onSubmit={handleFormSubmit}>
                <div
                    style={{
                        height: 'calc(100vh - 80px - 96px)',
                        overflowY: 'auto',
                        padding: '12px',
                    }}
                >
                    {
                        user.isAdmin && <>
                            <Header className='experience-form-header'>
                                Destination
                            </Header>
                            <Divider className='experience-form-divider'/>
                            <ExperienceFormDestination/>

                            <Header className='experience-form-header'>
                                Topics
                            </Header>
                            <Divider className='experience-form-divider'/>
                            <ExperienceFormTopics/>

                            <Header className='experience-form-header'>
                                Description
                            </Header>
                            <Divider className='experience-form-divider'/>
                            <ExperienceFormDescription/>
                        </>
                    }

                    <Header className='experience-form-header'>
                        Contact
                    </Header>
                    <Divider className='experience-form-divider'/>
                    <ExperienceFormContact/>

                    <Header className='experience-form-header'>
                        Payment methods
                    </Header>
                    <Divider className="experience-form-divider"/>
                    <ExperienceFormPaymentMethods/>

                    <Header className='experience-form-header'>
                        Features
                    </Header>
                    <Divider className="experience-form-divider"/>
                    <ExperienceFormFeatures/>

                    <Header className='experience-form-header'>
                        Schedule
                    </Header>
                    <Divider className="experience-form-divider"/>
                    <ExperienceFormSchedule/>

                    <Header className='experience-form-header'>
                        Location
                    </Header>
                    <Divider className="experience-form-divider"/>
                    <ExperienceFormLocation height={'50vh'}/>

                    <Header className='experience-form-header'>
                        Images
                    </Header>
                    <Divider className="experience-form-divider"/>
                    <ExperienceFormImages/>

                    <Header className='experience-form-header'>
                        Products
                    </Header>
                    <Divider className="experience-form-divider"/>
                    <ExperienceFormProducts/>
                    {
                        experience.hasError() && <Message error visible>
                            {experience.error}
                        </Message>
                    }
                </div>
                <ExperienceFormSubmitButton/>
            </Form>
        </Grid.Column>
    );
};
