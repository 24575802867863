import React from 'react';
import './ExperienceFormCheckbox.css';

export const ExperienceFormCheckbox = ({
                                           label, checked, onChange,
                                       }) => {

    return (<div className='form-experience-radio-container'>
        <input type='checkbox'
               checked={checked}
               onChange={onChange}
               className='form-experience-radio'
        />
        <label className='form-experience-radio-label'>
            {label}
        </label>
        <br/>
    </div>);
};