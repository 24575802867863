import {getAxios} from "../services/Axios";
import {getDestinationsUrl} from "../constants/url";
import {handleError} from "./appDucks";

const INITIAL_STATE = {
    destinations: null,
    loading: false,
    error: null
}

const ACTIONS = {
    UPDATE_DESTINATIONS: 'DESTINATIONS_UPDATE_DESTINATIONS',
    START_LOADING: 'DESTINATIONS_START_LOADING',
    STOP_LOADING: 'DESTINATIONS_STOP_LOADING',
}

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ACTIONS.UPDATE_DESTINATIONS:
            return {...state, destinations: action.payload}
        case ACTIONS.START_LOADING:
            return {...state, loading: true}
        case ACTIONS.STOP_LOADING:
            return {...state, loading: false}
        default:
            return state
    }
}

const fromMessageToDestination = message => ({
    id: message.id,
    name: message.name,
});

export const getDestinations = () => async (dispatch) => {
    await dispatch({type: ACTIONS.START_LOADING})
    let destinations;
    try {
        const params = {
            page_size: 500
        }
        const response = await getAxios(getDestinationsUrl, params);
        destinations = response.data.destinations.map(fromMessageToDestination)
        await dispatch({type: ACTIONS.UPDATE_DESTINATIONS, payload: destinations})
    } catch (error) {
        await dispatch(handleError(error));
    } finally {
        await dispatch({type: ACTIONS.STOP_LOADING})
    }
}
