import {getAxios, patchAxios, postAxios} from "../services/Axios";
import {getExperiencesUrl, getExperienceUrl, postExperienceUrl, putExperienceUrl} from '../constants/url';
import {fromExperienceToMessage, fromMessageToExperience} from "../v2/experience/message";
import {handleError} from "./appDucks";
import {ExperienceField} from "../v2/experience/field";
import {mockExperienceField} from "../v2/experience/__mocks__/field.mock";


const INITIAL_STATE = {
    experience: null,
    experiences: null,
    loading: false,
};

const EXPERIENCES_CLEAR_EXPERIENCES = 'EXPERIENCES_CLEAR_EXPERIENCES';
const EXPERIENCES_START_LOADING = 'EXPERIENCES_START_LOADING';
const EXPERIENCES_STOP_LOADING = 'EXPERIENCES_STOP_LOADING';
const EXPERIENCES_UPDATE_EXPERIENCES = 'EXPERIENCES_UPDATE_EXPERIENCES';

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case EXPERIENCES_CLEAR_EXPERIENCES:
            return {...state, experience: null};
        case EXPERIENCES_START_LOADING:
            return {...state, loading: true};
        case EXPERIENCES_STOP_LOADING:
            return {...state, loading: false};
        case EXPERIENCES_UPDATE_EXPERIENCES:
            return {
                ...state,
                experience: action.payload.experience ?? state.experience,
                experiences: action.payload.experiences ?? state.experiences,
            };
        default:
            return state;
    }
};

export const clearExperience = () => async (dispatch) => {
    dispatch({
        type: EXPERIENCES_CLEAR_EXPERIENCES,
    });
}

const getBusinessId = (state, {allowAll = false} = {}) => {
    // Retrieve the user from the store.
    const user = state.authentication.user;
    // If the user is admin, return "all".
    if (user.isAdmin && allowAll) {
        return "all";
    }
    // Otherwise, return the business id.
    return user.businessId;
};

export const getExperiences = () => async (dispatch, getState) => {
    dispatch({type: EXPERIENCES_START_LOADING});
    try {
        const url = getExperiencesUrl.replace(":business_id", getBusinessId(getState(), {allowAll: true}));
        const params = {
            page_size: 500
        }
        const response = await getAxios(url, params);
        const experiences = [];
        for (const item of response.data.entries) {
            let experience = await fromMessageToExperience(item);
            experiences.push(experience);
        }
        dispatch({
            type: EXPERIENCES_UPDATE_EXPERIENCES,
            payload: {
                experiences: experiences
            }
        });
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch({type: EXPERIENCES_STOP_LOADING});
    }
};

export const getExperience = (id) => async (dispatch, getState) => {
    dispatch({type: EXPERIENCES_START_LOADING});
    try {
        let url = getExperienceUrl;
        url = url.replace(':business_id', getBusinessId(getState(), {allowAll: true}));
        url = url.replace(':experience_id', id);
        const response = await getAxios(url);
        const experience = await fromMessageToExperience(response.data);
        dispatch({
            type: EXPERIENCES_UPDATE_EXPERIENCES,
            payload: {experience: experience}
        });
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch({type: EXPERIENCES_STOP_LOADING});
    }
};

export const createLocalExperience = () => async (dispatch) => {
    // Declare the experience.
    let experience;
    // If NODE_ENV is development, use the mock experience.
    if (process.env.NODE_ENV === 'development') {
        experience = mockExperienceField();
        experience.setModified(true);
    } else {
        experience = new ExperienceField();
        experience.value.features.setModified(true);
        experience.value.location.setModified(true);
        experience.value.schedule.value.stayMinutes.setModified(true);
        experience.value.status.value.state.setModified(true);
    }

    dispatch({
        type: EXPERIENCES_UPDATE_EXPERIENCES,
        payload: {
            experience: experience,
        }
    });
}

export const createExperience = () => async (dispatch, getState) => {
    dispatch({type: EXPERIENCES_START_LOADING});
    const {experience} = getState().experiences;
    try {
        const url = postExperienceUrl.replace(':business_id', getBusinessId(getState()));
        const body = await fromExperienceToMessage(experience, {modifiedOnly: true});
        const response = await postAxios(url, body);
        const responseExperience = await fromMessageToExperience(response.data);
        dispatch(upsertExperienceIntoLocalExperiences(responseExperience));
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch({type: EXPERIENCES_STOP_LOADING});
    }
};

export const updateExperience = () => async (dispatch, getState) => {
    dispatch({type: EXPERIENCES_START_LOADING});
    const {experience} = getState().experiences;
    try {
        let url = putExperienceUrl;
        url = url.replace(':business_id', getBusinessId(getState(), {allowAll: true}));
        url = url.replace(':experience_id', experience.value.id.value);
        const body = await fromExperienceToMessage(experience, {modifiedOnly: true});
        const response = await patchAxios(url, body);
        const responseExperience = await fromMessageToExperience(response.data);
        dispatch(upsertExperienceIntoLocalExperiences(responseExperience));
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch({type: EXPERIENCES_STOP_LOADING});
    }
};

export const updateLocalExperience = (experience) => async (dispatch) => {
    // Dispatch the experience.
    dispatch({
        type: EXPERIENCES_UPDATE_EXPERIENCES,
        payload: {
            experience: experience
        }
    });
}

export const updateLocalExperienceValue = (patch) => async (dispatch, getState) => {
    // Retrieve the local experience.
    let {experience} = getState().experiences;
    // Patch the local experience.
    experience = await experience
        .duplicate()
        .setValue({
            ...experience.value,
            ...patch
        });
    // Dispatch the patched experience.
    dispatch({
        type: EXPERIENCES_UPDATE_EXPERIENCES,
        payload: {
            experience: experience
        }
    });
}

const upsertExperienceIntoLocalExperiences = (patched) => async (dispatch, getState) => {
    try {
        let payload = {experience: patched};
        let {experiences} = getState().experiences;
        if (experiences) {
            const index = experiences.findIndex(experience => experience.value.id.value === patched.value.id.value);
            if (index === -1) {
                experiences.push(payload.experience);
            } else {
                experiences[index] = payload.experience;
            }
            payload.experiences = experiences;
        }
        dispatch({
            type: EXPERIENCES_UPDATE_EXPERIENCES,
            payload: payload
        });
    } catch (error) {
        dispatch(handleError(error));
    }
};
