import React, {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Button, Grid, Header, Label} from 'semantic-ui-react';
import FacebookButton from '../../../../components/FacebookButton';
import * as ROUTES from '../../../../constants/routes';
import Logo from '../../../../assets/Logos/white-logo.png';
import BusinessIcon from '../../../../assets/Components/business.png';
import ArrowIcon from '../../../../assets/Components/arrow-right.png';
import './LoginInfo.css';

const LoginInfo = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Grid.Column>
            <Grid.Row textAlign='left'>
                <Grid>
                    <Grid.Column width={5}>
                        <img src={Logo} alt='' className='login-logo'/>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Header className='login-info-title'>
                            The best platform to expose your business
                        </Header>
                    </Grid.Column>
                </Grid>
            </Grid.Row>
            <Grid.Row textAlign='left'>
                <Label className='login-info-sub-title'>
                    Expose your business to 1M+ tourists who vist Costa Rica every year.
                    Do it now!
                </Label>
            </Grid.Row>
            <Grid.Row textAlign='left'>
                <Grid>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Button as={Link} to={ROUTES.REGISTER} className='login-button-register'>
                            <img src={BusinessIcon} alt='' className='login-info-business-icon'/>
                            Sign up
                            <img src={ArrowIcon} alt='' className='login-info-arrow-right-icon'/>
                        </Button>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <FacebookButton/>
                    </Grid.Column>
                </Grid>
            </Grid.Row>
        </Grid.Column>
    );
};

export default LoginInfo;