import React from "react";
import {ExperienceFormCheckbox} from "./ExperienceFormCheckbox/ExperienceFormCheckbox";
import {updateLocalExperienceValue} from "../../../../redux/experienceDucks";
import {useDispatch, useSelector} from "react-redux";
import {Message} from "semantic-ui-react";

export const ExperienceFormPaymentMethods = () => {
    // A selector for retrieving the payment methods of the experience from the store.
    const paymentMethods = useSelector(state => state.experiences.experience.value.paymentMethods);

    // A dispatch for patching the payment methods of the experience in the store.
    const dispatch = useDispatch();

    // A callback for handling the change of the payment methods.
    const handlePaymentMethodChange = async (key) => {
        // Set the new value of the payment methods.
        const value = await paymentMethods.value[key].setValue(!paymentMethods.value[key].value);
        await paymentMethods.setValue({
            ...paymentMethods.value,
            [key]: value
        });
        // Send the new payment methods to the parent component.
        dispatch(updateLocalExperienceValue({paymentMethods}));
    }

    return <>
        {
            paymentMethods.hasError() &&
            <Message visible error content={paymentMethods.error}/>
        }
        {
            Object.getOwnPropertyNames(paymentMethods.value).map((name, index) => {
                return (<ExperienceFormCheckbox
                    key={index}
                    label={paymentMethods.value[name].label}
                    checked={paymentMethods.value[name].value}
                    onChange={handlePaymentMethodChange.bind(this, name)}
                />);
            })
        }
    </>
}
