import {Field} from "../field";

export class StringField extends Field {
    constructor({value = '', required = false} = {}) {
        super(value, {required});
    }

    hasValue() {
        return super.hasValue() && this.value.length > 0;
    }
}
