import React from 'react';
import {useSelector} from 'react-redux';
import {Grid} from 'semantic-ui-react';
import {ReactComponent as StepActiveIcon} from '../../../../assets/Icons/step-active.svg';
import {ReactComponent as StepInactiveIcon} from '../../../../assets/Icons/step-inactive.svg';

import './Stepper.css';

const Stepper = () => {

    const activeStep = useSelector(store => store.authentication.activeStep);

    return (
        <Grid>
            <Grid.Row centered verticalAlign='middle' className='row-register-stepper'>
                <i aria-hidden="true" className="icon register-stepper-icon">
                    {activeStep < 0 ? <StepInactiveIcon /> : <StepActiveIcon />}
                </i>
                <i aria-hidden="true" className="icon register-stepper-icon">
                    {activeStep < 1 ? <StepInactiveIcon /> : <StepActiveIcon />}
                </i>
            </Grid.Row>
        </Grid>
    );
};

export default Stepper;