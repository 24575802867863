import React, {useEffect, useRef, useState} from "react";
import {Grid, Header, Icon, Label, List, Segment} from "semantic-ui-react";
import "./ExperienceFormPreview.css";
import {NextIcon} from "../../../../../components/NextIcon/NextIcon";
import GoogleMap from "../../../../../components/Map/GoogleMap";
import {useSelector} from "react-redux";
import {Strings} from "../../../../../v2/string/string";
import {ExperienceFormPreviewImages} from "../ExperienceFormPreviewImages";

export const ExperienceFormPreview = () => {
    // A const for the maximum number of features per row.
    const maxFeaturesPerRow = 4;

    // A const for retrieving the experience from the Redux store.
    const experience = useSelector(store => store.experiences.experience);

    // A state variable for storing the features.
    const [features, setFeatures] = useState({});

    // A const for storing the ref of the map.
    const mapRef = useRef(null);

    function renderExperienceContact() {
        return <Grid.Column>
            <Header as={'h3'}>Contact</Header>
            {experience.value.contact.value.phoneNumber.hasValue() &&
                <div
                    style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Icon name={'phone'}/>
                    <span>{experience.value.contact.value.phoneNumber.value}</span>
                </div>
            }
            {experience.value.contact.value.email.hasValue() &&
                <div
                    style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Icon name={'mail'}/>
                    <span>{experience.value.contact.value.email.value}</span>
                </div>
            }
            {experience.value.contact.value.websiteUrl.hasValue() &&
                <div
                    style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Icon name={'globe'}/>
                    <span style={{}}>
                        {experience.value.contact.value.websiteUrl.value}
                    </span>
                </div>
            }
            {experience.value.contact.value.facebookUrl.hasValue() &&
                <div
                    style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Icon name={'facebook'}/>
                    <span>{experience.value.contact.value.facebookUrl.value}</span>
                </div>
            }
            {experience.value.contact.value.instagramUrl.hasValue() &&
                <div
                    style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Icon name={'instagram'}/>
                    <span>{experience.value.contact.value.instagramUrl.value}</span>
                </div>
            }
            {experience.value.contact.value.whatsApp.hasValue() &&
                <div
                    style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Icon name={'whatsapp'}/>
                    <span>{experience.value.contact.value.whatsApp.value}</span>
                </div>
            }
        </Grid.Column>;
    }

    function renderFeatureLabel(label) {
        // Distribute the words of the name in two lines.
        const words = label.split(' ');
        const firstLine = words.slice(0, words.length / 2).join(' ');
        const secondLine = words.slice(words.length / 2).join(' ');
        // Return the name of the feature.
        return (
            <>
                {firstLine}<br/>{secondLine}
            </>
        );
    }

    function getComplementaryFeatures(features) {
        // Initialize the complementary features.
        const complements = {
            notWheelchairAccessible: {
                features: ["wheelchairAccessible"],
                label: "Not Wheelchair Accessible",
            },
            noParking: {
                features: ["parking"],
                label: "No Parking",
            },
            notPetFriendly: {
                features: ["petFriendly"],
                label: "Not Pet Friendly",
            },
        };
        // For complement, if the every feature in value is false, add the complement.
        const complementaryFeatures = {}
        for (const [complement, value] of Object.entries(complements)) {
            if (value.features.every(feature => !features[feature].value)) {
                complementaryFeatures[complement] = {value: true, label: value.label};
            }
        }
        // Return the complementary features.
        return complementaryFeatures;
    }

    function renderExperienceFeatures() {
        // Split the features into rows of 5 features.
        const rows = [];
        let row = [];
        for (const [name, {value: active, label: label}] of Object.entries(features)) {
            if (active) {
                row.push({
                    name: name,
                    label: label,
                    size: 'small',
                });
            }
            if (row.length === maxFeaturesPerRow) {
                rows.push(row);
                row = [];
            }
        }
        if (row.length > 0) {
            rows.push(row);
        }
        return <Grid columns={maxFeaturesPerRow}>
            <Grid.Row>
                <Header as={'h3'}>Features</Header>
            </Grid.Row>
            {rows.map((row, index) => {
                return <Grid.Row key={index} style={{paddingTop: "4px", paddingBottom: "4px"}}>
                    {
                        row.map((feature, index) => {
                            return <Grid.Column key={index} style={{
                                padding: "4px", textAlign: "center"
                            }}>
                                <NextIcon name={feature.name} size={feature.size}/>
                                <p style={{fontSize: 10}}>
                                    {renderFeatureLabel(feature.label)}
                                </p>
                            </Grid.Column>
                        })
                    }
                </Grid.Row>
            })}
        </Grid>
    }

    function handleMapReady(google, map) {
        // Store the map in the map ref.
        mapRef.current = map;
    }

    function getExperienceLatLng() {
        // Retrieve the latitude and longitude from the experience.
        const latitude = experience.value.location.value.latitude.value;
        const longitude = experience.value.location.value.longitude.value;
        // Format to 5 decimal places.
        return `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`;
    }

    function renderExperienceLocation() {
        return <Grid.Column>
            <Header as={'h3'}>Location</Header>
            <Label color={'teal'} ribbon>
                <Icon name={'location arrow'}/>{getExperienceLatLng()}
            </Label>
            <GoogleMap
                markerPosition={{
                    lat: experience.value.location.value.latitude.value,
                    lng: experience.value.location.value.longitude.value,
                }}
                height={'200px'}
                draggable={false}
                onMapReady={handleMapReady}
            />
        </Grid.Column>;
    }

    // An effect to pan the map to the experience location every time it changes.
    useEffect(() => {
        // If there is no map, return.
        if (!mapRef.current) {
            return;
        }
        // Retrieve the map.
        const map = mapRef.current;
        // Retrieve the experience location.
        const location = {
            lat: experience.value.location.value.latitude.value,
            lng: experience.value.location.value.longitude.value,
        };
        // Pan the map to the experience location.
        map.panTo(location);
    }, [experience.value.location.value.latitude.value, experience.value.location.value.longitude.value]);

    useEffect(() => {
        let features = {};
        // Create a copy of the features.
        for (const [name, value] of Object.entries(experience.value.features.value)) {
            features[name] = {
                value: value.value,
                label: value.label,
            };
        }
        // Push the complementary features.
        features = {...features, ...getComplementaryFeatures(features)};
        // Set the features.
        setFeatures(features);
    }, [experience.value.features.value]);

    const DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    function fromTimetablesToDaySchedules(timetables) {
        const daySchedules = [];
        for (const timetable of timetables) {
            const daySchedule = {
                day: DAY_NAMES[timetable.wday],
                schedule: `${timetable.open_hour} - ${timetable.close_hour}`
            }
            daySchedules.push(daySchedule);
        }
        // If the first day is Sunday, move it to the end.
        if (daySchedules.length !== 0 && daySchedules[0].day === 'Sunday') {
            daySchedules.push(daySchedules.shift());
        }
        // Return the day schedules.
        return daySchedules;
    }

    function renderExperienceSchedule() {
        const daySchedules = fromTimetablesToDaySchedules(experience.value.schedule.value.timetables.value);
        return <Grid.Column>
            <Header as={'h3'}>Schedule</Header>
            <List>
                {
                    daySchedules.map((daySchedule, index) => {
                        return <List.Item key={index}>
                            <List.Header>{daySchedule.day}</List.Header>
                            <List.Content>{daySchedule.schedule}</List.Content>
                        </List.Item>
                    })
                }
            </List>
        </Grid.Column>;
    }

    function renderExperiencePaymentMethods() {
        // Retrieve the payment methods from the experience.
        const paymentMethods = experience.value.paymentMethods.value;
        // Retrieve the count of payment methods.
        const paymentMethodsCount = Object.values(paymentMethods).length;
        return <Grid.Column>
            <Header as={'h3'}>Payment Methods</Header>
            <Grid columns={paymentMethodsCount}>
                <Grid.Row>
                    {
                        paymentMethods.sinpeMovil.value &&
                        <Grid.Column>
                            <NextIcon name={'sinpeMovil'} size={'small'}/>
                        </Grid.Column>
                    }
                    {
                        paymentMethods.masterCard.value &&
                        <Grid.Column>
                            <NextIcon name={'masterCard'} size={'small'}/>
                        </Grid.Column>
                    }
                    {
                        paymentMethods.americanExpress.value &&
                        <Grid.Column>
                            <NextIcon name={'americanExpress'} size={'small'}/>
                        </Grid.Column>
                    }
                    {
                        paymentMethods.visa.value &&
                        <Grid.Column>
                            <NextIcon name={'visa'} size={'small'}/>
                        </Grid.Column>
                    }
                    {
                        paymentMethods.bitcoin.value &&
                        <Grid.Column>
                            <NextIcon name={'bitcoin'} size={'small'}/>
                        </Grid.Column>
                    }
                    {
                        paymentMethods.payPal.value &&
                        <Grid.Column>
                            <NextIcon name={'payPal'} size={'small'}/>
                        </Grid.Column>
                    }
                    {
                        paymentMethods.bankTransfer.value &&
                        <Grid.Column>
                            <NextIcon name={'bankTransfer'} size={'small'}/>
                        </Grid.Column>
                    }
                    {
                        paymentMethods.cash.value &&
                        <Grid.Column>
                            <NextIcon name={'cash'} size={'small'}/>
                        </Grid.Column>
                    }
                </Grid.Row>
            </Grid>
        </Grid.Column>;
    }

    return (
        <>
            <Segment
                raised
                style={{
                    margin: '32px 96px',
                    padding: 0,
                    height: 'calc(100vh - 80px - 64px)',
                    maxHeight: '720px'
                }}
            >
                <Grid style={{padding: 0, margin: 0}}>
                    <Grid.Column
                        width={10}
                        style={{
                            display: 'flex',
                            height: 'calc(100vh - 80px - 64px)',
                            maxHeight: '720px',
                            padding: '16px',
                        }}
                        verticalAlign={'middle'}
                        stretched
                    >
                        <ExperienceFormPreviewImages/>
                    </Grid.Column>
                    <Grid.Column
                        width={6}
                        style={{
                            height: 'calc(100vh - 80px - 64px)',
                            maxHeight: '720px',
                            overflowY: 'auto',
                            padding: 0,
                        }}
                    >
                        <Grid style={{margin: 0}}>
                            <Grid.Row textAlign={'left'} style={{height: '96px'}} verticalAlign={'middle'}>
                                <Grid.Column>
                                    <Header
                                        as={'h1'}>{Strings.getOrDefault(experience.value.contact.value.name.value, "Name")}</Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{padding: 0}}>
                                <Grid
                                    style={{
                                        overflowY: 'auto',
                                        margin: 0,
                                        height: 'calc(100vh - 80px - 64px - 96px)',
                                        maxHeight: 'calc(720px - 96px)',
                                    }}
                                >
                                    <Grid.Row>
                                        {renderExperienceContact()}
                                    </Grid.Row>
                                    <Grid.Row>
                                        {renderExperienceFeatures()}
                                    </Grid.Row>
                                    <Grid.Row>
                                        {renderExperienceLocation()}
                                    </Grid.Row>
                                    <Grid.Row>
                                        {renderExperienceSchedule()}
                                    </Grid.Row>
                                    <Grid.Row>
                                        {renderExperiencePaymentMethods()}
                                    </Grid.Row>
                                </Grid>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </Segment>
        </>
    )
}
