import React, {useEffect, useState} from 'react';
import {Dropdown, Message} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {updateLocalExperienceValue} from "../../../../redux/experienceDucks";

export const ExperienceFormDestination = () => {
    const experienceDestination = useSelector(state => state.experiences.experience.value.destination);

    const destinations = useSelector(state => state.destinations.destinations);

    const [destinationItems, setDestinationItems] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!destinations) {
            return;
        }
        setDestinationItems(destinations.map(destination => {
            return {
                key: destination.id,
                text: destination.name,
                value: destination.id
            }
        }))
    }, [destinations]);

    const handleChange = async (event, data) => {
        const destination = destinations.find(destination => destination.id === data.value);
        await experienceDestination.setValue({
            id: await experienceDestination.value.id.setValue(destination.id),
            name: await experienceDestination.value.name.setValue(destination.name)
        });
        await dispatch(updateLocalExperienceValue({destination: experienceDestination}));
    };

    return (
        <>
            {
                experienceDestination.hasError() &&
                <Message error visible>
                    {experienceDestination.error}
                </Message>
            }
            <Dropdown
                placeholder='Select a destination'
                fluid
                onChange={handleChange}
                options={destinationItems}
                search
                selection
                value={experienceDestination.value.id.value}
            />
        </>
    )
};
