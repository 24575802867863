export const validateUserData = user => {
    const error = {};
    const onlyLetters = /^[a-zñáéíóúüñ]*$/i;

    if (user.firstName === null || user.firstName.length === 0 || /^\s+$/.test(user.firstName)) {
        error.firstName = "Please enter a first name";
    } else if (!user.firstName.replace(" ", "").match(onlyLetters)) {
        error.firstName = "Please enter letters only";
    }

    if (user.lastName === null || user.lastName.length === 0 || /^\s+$/.test(user.lastName)) {
        error.lastName = "Please enter a last name";
    } else if (!user.lastName.replace(" ", "").match(onlyLetters)) {
        error.lastName = "Please enter letters only";
    }

    if (user.phoneNumber === null || user.phoneNumber.length === 0 || /^\s+$/.test(user.phoneNumber)) {
        error.phoneNumber = "Please enter a phone number";
    } else if (!(/^\d{8}$/.test(user.phoneNumber))) {
        error.phoneNumber = "Please enter an 8 digit phone number";
    }

    if (user.email === null || user.email.length === 0
        || /^\s+$/.test(user.email)) {
        error.email = "Please enter an email address";
    } else if (!validateEmailFormat(user.email)) {
        error.email = "Please enter a valid email address";
    }
    return error;
};

export const validatePassword = user => {
    if (!user.terms) {
        return "Please accept the terms and conditions";
    }
    if (user.password === null || user.password.length === 0 || /^\s+$/.test(user.password)) {
        return "Please enter a password";
    } else if (user.password.length < 8) {
        return "Please enter a password with at least 8 characters";
    } else if (user.password !== user.confirmPassword) {
        return "Please enter the same password";
    }
    return null;
};

export const validateUserDataFormat = user => {
    return Object.keys(validateUserData(user)).length === 0 && validatePassword(user) === null;
};

export const validateEmailFormat = email => {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};


