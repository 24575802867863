import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Form, Grid, Header, Icon} from 'semantic-ui-react';
import FormInput from '../../../components/FormInput';
import {ReactComponent as EmailEmptyIcon} from '../../../assets/Icons/email-empty.svg';
import {ReactComponent as EmailCompleteIcon} from '../../../assets/Icons/email-complete.svg';
import * as ROUTES from '../../../constants/routes';
import './FormDataEmail.css';

const FormDataEmail = ({
                           user,
                           handleOnSubmit,
                           handleOnChange,
                           error
                       }) => {

    return (
        <Form className='form-login-forgot-password-email' onSubmit={handleOnSubmit}>
            <Header className='title-form-login-forgot-password-email'>
                Enter your email
            </Header>
            <Header className='sub-title-form-login-forgot-password-email'>
                Enter your email to start the restoration process.
            </Header>
            <FormInput
                name='email'
                placeholder='Email'
                type='text'
                IconInput={!user.email ? EmailEmptyIcon : EmailCompleteIcon}
                handleOnChange={handleOnChange.bind(this)}
                error={error.emailError}
            />
            <Grid className='login-forgot-email-footer-container'>
                <Grid.Column floated='right' width={12}>
                    <Header
                        as={Link}
                        to={ROUTES.LOGIN}
                        className='login-forgot-email-back-login'
                    >
                        Back to sign in
                    </Header>
                </Grid.Column>
                <Grid.Column floated='right' width={12}
                             className='login-forgot-email-container-email-button'
                >
                    <Button
                        icon
                        labelPosition='right'
                        style={{
                            maxWidth: '219px',
                            width: '100%',
                            height: '51px',
                            borderRadius: '35px',
                            marginRight: '0',
                            float: 'right',
                            fontSize: '16px',
                            fontWeight: '500',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: '1.13',
                            letterSpacing: 'normal',
                            textAlign: 'center',
                            backgroundColor: 'var(--principal-color-page)',
                            color: '#FFFFFF'
                        }}
                    >
                        Send email
                        <Icon
                            name='play'
                            size='small'
                            style={{
                                backgroundColor: 'transparent',
                                right: '28px',
                                color: '#FFFFFF',
                                paddingLeft: 'unset',
                                paddingRight: '0.9em'
                            }}
                        />
                    </Button>
                </Grid.Column>
            </Grid>
        </Form>
    );
};

export default FormDataEmail