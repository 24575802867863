import {resetStore} from "./store";

const INITIAL_MESSAGE = {
    header: '',
    body: '',
    type: '',
    visible: false
};

const INITIAL_STATE = {
    message: INITIAL_MESSAGE
};

const APPLICATION_DISPLAY_MESSAGE = 'APPLICATION_DISPLAY_MESSAGE';
const APPLICATION_DISMISS_MESSAGE = 'APPLICATION_DISMISS_MESSAGE';

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case APPLICATION_DISPLAY_MESSAGE:
            return {
                ...state,
                message: {
                    header: action.payload.header,
                    body: action.payload.body,
                    type: action.payload.type,
                    visible: true
                }
            };
        case APPLICATION_DISMISS_MESSAGE:
            return {
                ...state,
                message: INITIAL_MESSAGE
            };
        default:
            return state;
    }
};

export const displayMessage = ({header, body, type}) => (dispatch) => {
    dispatch({
        type: APPLICATION_DISPLAY_MESSAGE,
        payload: {
            header,
            body,
            type
        }
    });
}

export const dismissMessage = () => (dispatch) => {
    dispatch({
        type: APPLICATION_DISMISS_MESSAGE
    });
}

export const handleError = (error) => (dispatch) => {
    const messages = []
    if (error.name === "AxiosError" && error.response) {
        messages.push(JSON.stringify(error.response.data));
    }
    if (error.message) {
        messages.push(error.message);
    }
    if (error.stack) {
        messages.push(error.stack);
    }
    console.error(messages.join('\n'));
    if (error.response && [401, 403].includes(error.response.status)) {
        dispatch({
            type: APPLICATION_DISPLAY_MESSAGE,
            payload: {
                header: 'Error',
                body: "Unauthorized",
                type: 'error'
            }
        });
        dispatch(resetStore());
        return;
    }
    dispatch({
        type: APPLICATION_DISPLAY_MESSAGE,
        payload: {
            header: 'Error',
            body: "Something went wrong",
            type: 'error'
        }
    });
}
