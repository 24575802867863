export const validatePasswordRequirements = (password, confirmPassword) => {
    const passwordLength = 8;
    const passwordRequirements = {
        isPasswordLength: false,
        isPasswordCapitalLetter: false,
        isPasswordNumber: false,
        isPasswordComplete: false,
    };
    if (password.length >= passwordLength)
        passwordRequirements.isPasswordLength = true;
    if (/[A-Z]/.test(password))
        passwordRequirements.isPasswordCapitalLetter = true;
    if (/[0-9]/.test(password))
        passwordRequirements.isPasswordNumber = true;
    if (passwordRequirements.isPasswordLength &&
        passwordRequirements.isPasswordCapitalLetter &&
        passwordRequirements.isPasswordNumber &&
        password === confirmPassword)
        passwordRequirements.isPasswordComplete = true;

    return passwordRequirements;
};
