import React from 'react';
import {Button, Form, Grid, Header, Icon} from 'semantic-ui-react';
import Stepper from '../Stepper/Stepper';
import FormInput from '../../../../components/FormInput';
import {ReactComponent as UserEmptyIcon} from '../../../../assets/Icons/user-empty.svg';
import {ReactComponent as UserCompleteIcon} from '../../../../assets/Icons/user-complete.svg';
import {ReactComponent as EmailEmptyIcon} from '../../../../assets/Icons/email-empty.svg';
import {ReactComponent as EmailCompleteIcon} from '../../../../assets/Icons/email-complete.svg';
import {ReactComponent as PhoneEmptyIcon} from '../../../../assets/Icons/phone-empty.svg';
import {ReactComponent as PhoneCompleteIcon} from '../../../../assets/Icons/phone-complete.svg';

const FormDataUser = ({
                          user,
                          handleOnSubmit,
                          handleOnChange,
                          handleUserError
                      }) => {

    return (
        <Form
            style={{
                width: '75%',
            }}
            onSubmit={handleOnSubmit}
        >
            <Header
                style={{
                    maxWidth: '273px',
                    width: '100%',
                    fontSize: '42px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.1',
                    letterSpacing: '0.9px',
                    color: 'var(--principal-color-page)',
                    paddingBottom: '10px'
                }}
            >
                Fill in your details
            </Header>
            <FormInput
                name='firstName'
                value={user.firstName}
                placeholder='First Name'
                type='text'
                IconInput={!user.firstName ? UserEmptyIcon : UserCompleteIcon}
                handleOnChange={handleOnChange.bind(this)}
                error={handleUserError.firstName}
            />
            <FormInput
                name='lastName'
                value={user.lastName}
                placeholder='Last Name'
                type='text'
                IconInput={!user.lastName ? UserEmptyIcon : UserCompleteIcon}
                handleOnChange={handleOnChange.bind(this)}
                error={handleUserError.lastName}
            />
            <FormInput
                name='email'
                value={user.email}
                placeholder='Email'
                type='text'
                IconInput={!user.email ? EmailEmptyIcon : EmailCompleteIcon}
                handleOnChange={handleOnChange.bind(this)}
                error={handleUserError.email}
            />
            <FormInput
                name='phoneNumber'
                value={user.phoneNumber}
                placeholder='Phone Number'
                type='text'
                IconInput={!user.phoneNumber ? PhoneEmptyIcon : PhoneCompleteIcon}
                handleOnChange={handleOnChange.bind(this)}
                error={handleUserError.phoneNumber}
            />
            <Stepper/>
            <Grid.Row
                className='register-data-user-container-button'
                style={{
                    paddingTop: '8%'
                }}
            >
                <Button
                    icon
                    labelPosition='right'
                    style={{
                        height: '50px',
                        maxWidth: '320px',
                        width: '100%',
                        borderRadius: '35px',
                        fontSize: '16px',
                        fontWeight: '500',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: '1.13',
                        letterSpacing: 'normal',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        backgroundColor: 'var(--principal-color-page)',
                        color: '#FFFFFF'
                    }}
                >
                    Next
                    <Icon
                        name='play'
                        size='small'
                        style={{
                            paddingLeft: 'unset',
                            paddingRight: '0.9em',
                            backgroundColor: 'transparent',
                            right: '50px',
                            color: '#FFFFFF'
                        }}
                    />
                </Button>
            </Grid.Row>
        </Form>
    );
};

export default FormDataUser;