import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Card, Grid, Search} from 'semantic-ui-react';
import ExperienceCardItemAdd from './CardItemAdd';
import {getExperiences} from '../../../../redux/experienceDucks';
import './CardList.css';
import ExperienceCardItem from "./CardItem";
import {ExperienceState} from "../../../../v2/experience/state";

const ExperienceCardList = ({setOpenMessage}) => {
    const dispatch = useDispatch();

    const experiences = useSelector(store => store.experiences.experiences);

    const [filters, setFilters] = useState({
        name: (_) => true,
        inReview: (_) => true,
    });

    const [isInReview, setIsInReview] = useState(false);

    useEffect(() => {
        if (experiences !== null) {
            return;
        }
        dispatch(getExperiences());
    }, [experiences]);

    const handleSearchChange = (event) => {
        // Get the query.
        const query = event.target.value;
        // Split the query into words.
        const words = query.split(' ');
        // For each word, create a capture group that matches the word and is case-insensitive.
        let regex = "";
        words.forEach(word => {
            regex += `(?=.*${word})`;
        });
        // Create a regex that matches the entire query, and is case-insensitive.
        regex = new RegExp(`${regex}.*`, 'i');
        // Set the name filter to a function that returns true if the name matches all regexes.
        setFilters({
            ...filters,
            name: (experience) => {
                return regex.test(experience.value.contact.value.name.value);
            }
        })
    };

    const handleInReviewClick = () => {
        // Declare the filter.
        let filter;
        // If in review, set the filter to true.
        if (isInReview) {
            filter = (_) => true;
        } else {
            // Otherwise, set the filter to a function that returns true if the experience is in review.
            filter = (experience) => {
                return experience.value.status.value.state.value === ExperienceState.READY_FOR_REVIEW;
            };
        }
        // Set the inReview filter to a function that returns true if the experience is in review.
        setFilters({
            ...filters,
            inReview: filter
        });
        // Set the state to the opposite of the current state.
        setIsInReview(!isInReview);
    };

    const filterExperiences = (experience) => {
        // For each filter
        for (let filter of Object.values(filters)) {
            // If the experience does not match the filter, return false.
            if (!filter(experience)) {
                return false;
            }
        }
        // If the experience matches all filters, return true.
        return true;
    };

    return (
        <Grid
            style={{
                margin: 0,
                padding: 0,
                height: 'calc(100vh - 80px)',
            }}
        >
            <Grid.Row
                style={{
                    height: 72,
                    padding: 0,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: "0px 14px"
                    }}
                >
                    <Search
                        className={"experience-card-list-search"}
                        fluid
                        onSearchChange={handleSearchChange}
                        placeholder="Search..."
                        size="big"
                        showNoResults={false}
                        style={{
                            width: '428px',
                            margin: 0,
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        circular
                        color={isInReview ? "teal" : null}
                        onClick={handleInReviewClick}
                        size="big"
                        style={{
                            fontSize: '14px',
                        }}
                    >
                        In Review
                    </Button>
                </div>
            </Grid.Row>
            <Grid.Row
                style={{
                    height: 'calc(100vh - 80px - 72px)',
                    padding: 0,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}
            >
                {/*
                We wrap the Card.Group to adjust the height of the group dynamically.
                Otherwise, the group expands to the full height of the parent.
                */}
                <div>
                    <Card.Group
                        itemsPerRow={6}
                        style={{
                            margin: '0px 14px',
                        }}
                    >
                        <ExperienceCardItemAdd/>
                        {experiences?.filter(filterExperiences)
                            .map(item => (
                                <ExperienceCardItem
                                    experience={item}
                                    key={item.value.id.value}
                                    setOpenMessage={setOpenMessage}
                                />
                            ))}
                    </Card.Group>
                </div>
            </Grid.Row>
        </Grid>
    )
        ;
};

export default ExperienceCardList;
