import {deleteAxios, getAxios, postAxios} from "../services/Axios";
import {deleteCreditCardUrl, getCreditCardsUrl, postCreditCardUrl, putCreditCardUrl} from '../constants/url';

const initialState = {
    cardList: [],
    loading: false,
    deleting: false,
    saving: false,
    error: null,
    visualError: null
};

const GET_CREDIT_CARDS_SUCCESS = 'GET_CREDIT_CARDS_SUCCESS';
const CREDIT_CARD_SUCCESS = 'CREDIT_CARD_SUCCESS';
const DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS';
const SET_DEFAULT_CREDIT_CARD_SUCCESS = 'SET_DEFAULT_CREDIT_CARD_SUCCESS';
const CREDIT_CARD_ERROR = 'CREDIT_CARD_ERROR';
const CREDIT_CARD_VISUAL_ERROR = 'CREDIT_CARD_VISUAL_ERROR';
const CREDIT_CARD_LOADING = 'CREDIT_CARD_LOADING';
const CREDIT_CARD_SAVING = 'CREDIT_CARD_SAVING';
const CREDIT_CARD_DELETING = 'CREDIT_CARD_DELETING';
const LOG_OUT = 'LOG_OUT';


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CREDIT_CARD_LOADING:
            return {...state, loading: true, error: null, visualError: null};
        case CREDIT_CARD_SAVING:
            return {...state, saving: true, error: null, visualError: null};
        case CREDIT_CARD_DELETING:
            return {...state, deleting: true, error: null, visualError: null};
        case GET_CREDIT_CARDS_SUCCESS:
            return {
                ...state, cardList: action.payload, error: null, visualError: null, loading: false
            };
        case CREDIT_CARD_SUCCESS:
            return {...state, error: null, visualError: null, saving: false};
        case DELETE_CREDIT_CARD_SUCCESS:
            return {...state, error: null, visualError: null, deleting: false};
        case SET_DEFAULT_CREDIT_CARD_SUCCESS:
            return {...state, error: null, visualError: null, loading: false};
        case CREDIT_CARD_ERROR:
            return {
                ...state, error: action.payload, loading: false, saving: false, deleting: false
            };
        case CREDIT_CARD_VISUAL_ERROR:
            return {
                ...state, visualError: action.payload, loading: false, saving: false, deleting: false
            };
        case LOG_OUT:
            return {...initialState};
        default:
            return state;
    }
};

export const getCreditCards = () => async (dispatch) => {
    dispatch({type: CREDIT_CARD_LOADING});
    try {
        const creditCardList = [];
        const creditCardsResult = await getAxios(getCreditCardsUrl);
        creditCardsResult.data.entries.forEach(card => {
            creditCardList.push({
                id: card.id,
                fullName: card.full_name,
                provider: card.provider,
                lastFourDigits: card.last_4_digits,
                isDefault: card.is_default,
                greenpayToken: card.greenpay_token,
            });
        });
        dispatch({
            type: GET_CREDIT_CARDS_SUCCESS,
            payload: creditCardList
        });
    } catch (error) {
        dispatch({
            type: CREDIT_CARD_ERROR,
            payload: 'Error al cargar las tarjetas de creditos'
        });
    }
};

export const addNewCreditCard = (card) => async (dispatch, getState) => {
    dispatch({type: CREDIT_CARD_SAVING});
    try {
        const {cardList} = getState().card;
        const actualYear = new Date().getFullYear();
        const cardYear = new Date(`01/01/${card.expiry.split('/')[1]}`).getFullYear();
        if (actualYear <= cardYear) {
            await postAxios(postCreditCardUrl, {
                'credit_card': {
                    'expiration_month': card.expiry.split('/')[0],
                    'expiration_year': cardYear.toString(),
                    'provider': card.provider,
                    'full_name': card.name,
                    'number': card.number.trim(),
                    'security_code': card.cvc.trim(),
                    'is_default': !cardList.length > 0
                }
            });
            dispatch({
                type: CREDIT_CARD_SUCCESS
            });
            dispatch(getCreditCards());
        } else
            throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 422)
            dispatch({
                type: CREDIT_CARD_VISUAL_ERROR,
                payload: 'El número de tarjeta no es válido'
            });
        dispatch({
            type: CREDIT_CARD_ERROR,
            payload: 'Error al guardar la tarjeta de credito'
        });
    }
};

export const setDefaultCard = (cardId) => async (dispatch) => {
    dispatch({type: CREDIT_CARD_LOADING});
    try {
        const url = putCreditCardUrl.replace(':id', cardId);
        await postAxios(url);
        dispatch({
            type: SET_DEFAULT_CREDIT_CARD_SUCCESS
        });
        dispatch(getCreditCards());
    } catch (error) {
        dispatch({
            type: CREDIT_CARD_ERROR,
            payload: 'Error al cambiar la tarjeta por defecto'
        });
    }
};

export const deleteCreditCard = (cardId) => async (dispatch, getState) => {
    dispatch({type: CREDIT_CARD_DELETING});
    try {
        const {cardList} = getState().card;
        const card = cardList.find(c => c.id === cardId && c.isDefault === true);

        if (cardList.length === 1 || card)
            return dispatch({
                type: CREDIT_CARD_VISUAL_ERROR,
                payload: 'No se puede eliminar la única tarjeta por defecto'
            });
        if (cardList.length > 1 && !card) {
            const url = deleteCreditCardUrl.replace(':id', cardId);
            await deleteAxios(url);
            dispatch({
                type: DELETE_CREDIT_CARD_SUCCESS
            });
            dispatch(getCreditCards());
        }
    } catch (error) {
        dispatch({
            type: CREDIT_CARD_ERROR,
            payload: 'Error al elimiar la tarjeta de credito'
        });
    }
}