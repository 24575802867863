export const getHourList = () => {
    const hourList = [];
    const secondsPerHour = 3600;
    const maxHourIndex = 48;
    for (let hourIndex = 0; hourIndex < maxHourIndex; hourIndex++) {
        const totalSeconds = ((hourIndex / 2) * secondsPerHour);
        const hours = Math.floor(totalSeconds / secondsPerHour);
        const minutes = Math.floor((totalSeconds / 60) % 60);
        const text = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
        hourList.push({index: hourIndex, seconds: totalSeconds, text: text});
    }
    hourList.push({index: 48, seconds: 0, text: '23:59'});
    return hourList;
}


export const getStayHourList = () => {
    const hourList = [];
    const minutesPerHour = 60;
    const maxHourIndex = 17;
    for (let hourIndex = 1; hourIndex < maxHourIndex; hourIndex++) {
        const totalMinutes = ((hourIndex / 2) * minutesPerHour);
        const hours = Math.floor(totalMinutes / minutesPerHour);
        const minutes = Math.floor(totalMinutes % 60);
        const text = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
        hourList.push({index: hourIndex, minutes: totalMinutes, text: text});
    }
    return hourList;
}
