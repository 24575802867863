import {fromFileUrlToBase64} from "../image";
import {ExperienceField} from "./field";

function fromExperiencePaymentMethodsToMessage(paymentMethods) {
    const values = {
        sinpe_movil: paymentMethods.value.sinpeMovil.value,
        visa_or_mastercard: paymentMethods.value.visa.value || paymentMethods.value.masterCard.value,
        american_express: paymentMethods.value.americanExpress.value,
        bitcoin: paymentMethods.value.bitcoin.value,
        pay_pal: paymentMethods.value.payPal.value,
        bank_transfer: paymentMethods.value.bankTransfer.value,
        cash: paymentMethods.value.cash.value,
    };
    return Object.keys(values).filter(key => values[key]);
}

async function fromExperienceImagesToMessage(images) {
    const messages = []
    for (const image of images.value) {
        if (image.id && image._destroy) {
            messages.push({
                id: image.id,
                _destroy: true,
            });
            continue;
        }
        if (image.id && image._update) {
            messages.push({
                id: image.id,
                primary: image.primary,
            });
            continue;
        }
        if (image.id) {
            continue;
        }
        const base64 = await fromFileUrlToBase64(image.pictureUrl);
        messages.push({
            picture: base64,
        });
    }
    return messages;
}

function fromExperienceProductsToMessage(products) {
    const messages = [];
    for (const product of products.value) {
        if (product.id && product._destroy) {
            messages.push({
                id: product.id,
                _destroy: true,
            });
            continue;
        }
        if (product.id && !product._update) {
            continue;
        }
        const message = {}
        if (product.id) {
            message.id = product.id;
        }
        message.name = product.name;
        message.minutes = product.duration * 60;
        message.price = product.price;
        message.description = product.description;
        messages.push(message);
    }
    return messages;
}

const fromExperienceTopicsToMessage = (topics) => {
    const weight = 1 / topics.value.length;
    const message = {};
    topics.value.forEach(topic => {
        message[topic] = weight;
    });
    return message;
};

const includeField = async (target, path, field, options = {modifiedOnly: true, serializer: undefined}) => {
    if (options.modifiedOnly && !field.isModified()) {
        return;
    }
    let value;
    if (options.serializer) {
        value = await options.serializer(field);
    } else {
        value = field.value;
    }
    target[path] = value;
}

function fromExperienceTimetablesToMessage(timetables) {
    const messages = []
    for (const timetable of timetables.value) {
        if (timetable.id && timetable._destroy) {
            messages.push({
                id: timetable.id,
                _destroy: true,
            });
            continue;
        }
        if (timetable.id && !timetable._update) {
            continue;
        }
        const message = {}
        if (timetable.id) {
            message.id = timetable.id;
        }
        message.wday = timetable.wday;
        message.open_hour = timetable.open_hour;
        message.close_hour = timetable.close_hour;
        messages.push(message);
    }
    return messages;
}

export const fromExperienceToMessage = async (experience, options = {modifiedOnly: false}) => {
    let message = {}
    await includeField(message, "accommodation", experience.value.features.value.accommodation, options);
    await includeField(message, "adults_only", experience.value.features.value.adultsOnly, options);
    await includeField(message, "all_terrain_vehicle_only", experience.value.features.value.allTerrainVehicleOnly, options);
    await includeField(message, "bird_watching", experience.value.features.value.birdWatching, options);
    await includeField(message, "camping", experience.value.features.value.camping, options);
    await includeField(message, "carbon_neutral", experience.value.features.value.carbonNeutral, options);
    await includeField(message, "description", experience.value.description, options);
    await includeField(message, "destination_id", experience.value.destination.value.id, options);
    await includeField(message, "email", experience.value.contact.value.email, options);
    await includeField(message, "ev_charger", experience.value.features.value.evCharger, options);
    await includeField(message, "facebook_url", experience.value.contact.value.facebookUrl, options);
    await includeField(message, "food_drinks", experience.value.features.value.foodDrink, options);
    await includeField(message, "images_attributes", experience.value.images, {
        ...options,
        serializer: fromExperienceImagesToMessage
    });
    await includeField(message, "instagram_url", experience.value.contact.value.instagramUrl, options);
    await includeField(message, "internet", experience.value.features.value.internet, options);
    await includeField(message, "latitude", experience.value.location.value.latitude, options);
    await includeField(message, "longitude", experience.value.location.value.longitude, options);
    await includeField(message, "parking", experience.value.features.value.parking, options);
    await includeField(message, "payment_methods", experience.value.paymentMethods, {
        ...options,
        serializer: fromExperiencePaymentMethodsToMessage
    });
    await includeField(message, "pet_friendly", experience.value.features.value.petFriendly, options);
    await includeField(message, "phone", experience.value.contact.value.phoneNumber, options);
    await includeField(message, "products_attributes", experience.value.products, {
        ...options,
        serializer: fromExperienceProductsToMessage
    });
    await includeField(message, "public_transport", experience.value.features.value.publicTransport, options);
    await includeField(message, "security_lockers", experience.value.features.value.securityLockers, options);
    await includeField(message, "showers", experience.value.features.value.showers, options);
    await includeField(message, "smoking_area", experience.value.features.value.smokingArea, options);
    await includeField(message, "state", experience.value.status.value.state, options);
    await includeField(message, "stay_in_minutes", experience.value.schedule.value.stayMinutes, options);
    await includeField(message, "timetables_attributes", experience.value.schedule.value.timetables, {
        ...options,
        serializer: fromExperienceTimetablesToMessage
    });
    await includeField(message, "title", experience.value.contact.value.name, options);
    await includeField(message, "topics", experience.value.topics, {
        ...options,
        serializer: fromExperienceTopicsToMessage
    });
    await includeField(message, "toilets", experience.value.features.value.toilets, options);
    await includeField(message, "website_url", experience.value.contact.value.websiteUrl, options);
    await includeField(message, "whats_app", experience.value.contact.value.whatsApp, options);
    await includeField(message, "wheelchair_accessible", experience.value.features.value.wheelchairAccessible, options);
    return message;
};

const fromMessageToExperienceProducts = (message) => {
    if (!message) {
        return [];
    }
    return message.map(function (product) {
        return {
            id: product.id,
            name: product.name,
            duration: product.minutes / 60,
            price: product.price,
            description: product.description,
        }
    });
};

export const fromMessageToExperience = async (message) => {
    const experience = new ExperienceField();

    await experience.value.id.setValue(message.id?.toString() ?? '', {validate: false, overwriteValueOnLoad: true});

    await experience.value.contact.value.name.setValue(message.title ?? '', {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.contact.value.phoneNumber.setValue(message.phone ?? '', {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.contact.value.email.setValue(message.email ?? '', {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.contact.value.websiteUrl.setValue(message.website_url ?? '', {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.contact.value.facebookUrl.setValue(message.facebook_url ?? '', {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.contact.value.instagramUrl.setValue(message.instagram_url ?? '', {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.contact.value.whatsApp.setValue(message.whats_app ?? '', {
        validate: false,
        overwriteValueOnLoad: true
    });

    await experience.value.paymentMethods.value.sinpeMovil.setValue(message.payment_methods?.includes("sinpe_movil") ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.paymentMethods.value.masterCard.setValue(message.payment_methods?.includes("visa_or_mastercard") ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.paymentMethods.value.americanExpress.setValue(message.payment_methods?.includes("american_express") ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.paymentMethods.value.visa.setValue(message.payment_methods?.includes("visa_or_mastercard") ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.paymentMethods.value.bitcoin.setValue(message.payment_methods?.includes("bitcoin") ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.paymentMethods.value.payPal.setValue(message.payment_methods?.includes("pay_pal") ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.paymentMethods.value.bankTransfer.setValue(message.payment_methods?.includes("bank_transfer") ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.paymentMethods.value.cash.setValue(message.payment_methods?.includes("cash") ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });

    await experience.value.features.value.accommodation.setValue(message.accommodation ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.adultsOnly.setValue(message.adults_only ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.allTerrainVehicleOnly.setValue(message.all_terrain_vehicle_only ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.birdWatching.setValue(message.bird_watching ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.camping.setValue(message.camping ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.carbonNeutral.setValue(message.carbon_neutral ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.evCharger.setValue(message.ev_charger ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.foodDrink.setValue(message.food_drinks ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.internet.setValue(message.internet ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.parking.setValue(message.parking ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.petFriendly.setValue(message.pet_friendly ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.publicTransport.setValue(message.public_transport ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.securityLockers.setValue(message.security_lockers ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.showers.setValue(message.showers ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.smokingArea.setValue(message.smoking_area ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.toilets.setValue(message.toilets ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.features.value.wheelchairAccessible.setValue(message.wheelchair_accessible ?? false, {
        validate: false,
        overwriteValueOnLoad: true
    });

    await experience.value.schedule.value.timetables.setValue(message.timetables ?? [], {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.schedule.value.stayMinutes.setValue(message.stay_in_minutes ?? 30, {
        validate: false,
        overwriteValueOnLoad: true
    });

    await experience.value.location.value.latitude.setValue(message.latitude ?? 0, {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.location.value.longitude.setValue(message.longitude ?? 0, {
        validate: false,
        overwriteValueOnLoad: true
    });

    await experience.value.images.setValue(message.images?.map(image => ({
        id: image.id,
        pictureUrl: image.picture_url,
        primary: image.primary,
    })), {validate: false, overwriteValueOnLoad: true});

    await experience.value.products.setValue(fromMessageToExperienceProducts(message.products), {
        validate: false,
        overwriteValueOnLoad: true
    });

    await experience.value.status.value.state.setValue(message.state?.toString(), {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.status.value.draftId.setValue(message.draft_id?.toString(), {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.status.value.publicationId.setValue(message.publication_id?.toString(), {
        validate: false,
        overwriteValueOnLoad: true
    });

    await experience.value.description.setValue(message.description ?? '', {
        validate: false,
        overwriteValueOnLoad: true
    });

    await experience.value.topics.setValue(message.topics ?? [], {validate: false, overwriteValueOnLoad: true});

    await experience.value.destination.value.id.setValue(message.destination?.id ?? '', {
        validate: false,
        overwriteValueOnLoad: true
    });
    await experience.value.destination.value.name.setValue(message.destination?.name ?? '', {
        validate: false,
        overwriteValueOnLoad: true
    });
    return experience;
};
