import React, {useState} from 'react'
import {Grid, Message} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {ExperienceFormProductCard} from "./ExperienceFormProductCard";
import {ExperienceFormProductAddButton} from "./ExperienceFormProductAddButton";
import {ExperienceFormProductAddForm} from "./ExperienceFormProductAddForm";
import {updateLocalExperienceValue} from "../../../../../redux/experienceDucks";

export const ExperienceFormProducts = () => {
    // A selector for retrieving the products from the Redux store
    let products = useSelector(state => state.experiences.experience.value.products);

    // A state for determining whether to add a new product
    const [addingProduct, setAddingProduct] = useState(false);

    const dispatch = useDispatch();

    const handleNewClick = () => {
        setAddingProduct(true);
    }

    const handleCancelClick = () => {
        setAddingProduct(false);
    };

    const handleProductAdd = async (product) => {
        // Add the product to the Redux store
        products = await products.setValue([
            ...products.value,
            product
        ]);
        await dispatch(updateLocalExperienceValue({products: products}));
        setAddingProduct(false);
    }

    const handleProductDelete = async (productIndex) => {
        // Duplicate the products
        let newProductsValue = products.value.slice();
        // Retrieve the product
        const product = newProductsValue[productIndex];
        // If identifier in product
        if (product.id) {
            // Mark product as ready to destroy
            newProductsValue[productIndex] = {
                ...newProductsValue[productIndex],
                _destroy: true
            }
        } else {
            // Remove the product from the Redux store
            newProductsValue = newProductsValue.filter((product, index) => index !== productIndex);
        }
        // Update the value of the products in the experience
        let newProducts = await products.setValue(newProductsValue);
        // Patch the experience in the store
        await dispatch(updateLocalExperienceValue({products: newProducts}))
    }

    return <>
        {
            products.hasError() && <Message negative visible>{products.error}</Message>
        }
        <Grid>
            <Grid.Column style={{paddingLeft: 0, paddingRight: 0}}>
                {
                    products.value.map((product, index) => {
                        if (product._destroy) {
                            return;
                        }
                        return <ExperienceFormProductCard
                            key={index}
                            product={product}
                            productIndex={index}
                            onProductDelete={handleProductDelete}
                        />;
                    })
                }
                {
                    addingProduct &&
                    <ExperienceFormProductAddForm products={products.value} onProductAdd={handleProductAdd}/>
                }
                {
                    <ExperienceFormProductAddButton
                        addingProduct={addingProduct}
                        onNewClick={handleNewClick}
                        onCancelClick={handleCancelClick}
                    />
                }
            </Grid.Column>
        </Grid>
    </>
}
