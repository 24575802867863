import {Field} from "./field";

export class FieldValidator {
    // Construct an instance
    // With parameter options set to an empty object
    constructor(options = {}) {
        // Set this.required to options.required
        this.required = options.required ?? false;
        // Set this.modifiedOnly to options.modifiedOnly
        this.modifiedOnly = options.modifiedOnly ?? false;
    }

    // Declare abstract method "getError"
    async getError(field) {
        // If not implemented, return null
        return null;
    }

    async validate(field) {
        // If field is not an instance of Field, raise error "Invalid field"
        if (!(field instanceof Field)) {
            throw new Error("Invalid field");
        }
        // Declare the error variable
        let error;
        // If field is required and has no value, set error "Please enter a value"
        if (this.required && !field.hasValue()) {
            error = "Please enter a value";
        }
        // If not required and no value
        else if (!this.required && !field.hasValue()) {
            error = null;
        }
        // If not required and not modified
        else if (!this.required && !field.isModified()) {
            error = null;
        }
        // If modified only and not modified
        else if (this.modifiedOnly && !field.isModified()) {
            error = null;
        }
        // Else (if modified)
        else {
            // Retrieve the error
            error = await this.getError(field);
        }
        // Set the error in the field
        field.setError(error);
        // Return whether there is an error
        return !error;
    }

    isValid(field) {
        // If required and no value, return false
        if (this.required && !field.hasValue()) {
            return false;
        }
        // If modified only and not modified, return false
        if (this.modifiedOnly && !field.isModified()) {
            return false;
        }
        // If field error is defined, return false
        if (field.error) {
            return false;
        }
        // If field value is an array
        if (Array.isArray(field.value)) {
            // For each value in field value
            for (const value of field.value) {
                // If value is not an instance of Field, return true
                if (!(value instanceof Field)) {
                    return true;
                }
                // If value is not valid, return false
                if (!value.isValid()) {
                    return false;
                }
            }

        }
        // If field value is object, return true
        if (typeof field.value === "object") {
            // For each key in field value
            for (const key in field.value) {
                // Create variable "value" and set it to field value key
                const value = field.value[key];
                // If value is not an instance of Field, throw error "Invalid field: <key>"
                if (!(value instanceof Field)) {
                    throw new Error(`Invalid field: ${key}`);
                }
                // If is not valid, return false
                if (!value.isValid()) {
                    return false;
                }
            }
        }
        // Return true
        return true;
    }

    attachTo(field) {
        // If field is not an instance of Field, throw error "Invalid field"
        if (!(field instanceof Field)) {
            throw new Error("Invalid field");
        }
        // Set field validator to this
        field.setValidator(this);
    }
}
