import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Form, Grid, Header, Icon} from 'semantic-ui-react';
import FormInput from '../../../components/FormInput';
import {ReactComponent as UserEmptyIcon} from '../../../assets/Icons/user-empty.svg';
import {ReactComponent as UserCompleteIcon} from '../../../assets/Icons/user-complete.svg';
import {ReactComponent as LockEmptyIcon} from '../../../assets/Icons/lock-empty.svg';
import {ReactComponent as LockCompleteIcon} from '../../../assets/Icons/lock-complete.svg';
import * as ROUTES from '../../../constants/routes';
import './FormDataLogin.css';
import {getPrivacyPolicyUrl} from "../../../constants/url";

const FormDataLogin = ({
                           user,
                           handleOnChange,
                           handleOnSubmit,
                           handleUserError,
                       }) => {

    return (
        <Form className='form-login' onSubmit={handleOnSubmit}>
            <Header className='title-form-login'>
                Sign in
            </Header>
            <FormInput
                name='email'
                placeholder='Email'
                type='email'
                IconInput={!user.email ? UserEmptyIcon : UserCompleteIcon}
                handleOnChange={handleOnChange.bind(this)}
                error={handleUserError.email}
            />
            <FormInput
                name='password'
                placeholder='Password'
                type='password'
                IconInput={!user.password ? LockEmptyIcon : LockCompleteIcon}
                handleOnChange={handleOnChange.bind(this)}
                error={handleUserError.password}
            />
            <Grid className='login-link-row'>
                <Grid.Column floated='right' width={12}>
                    <Header
                        as={Link}
                        to={ROUTES.PASSWORD_FORGOT}
                        className='login-link'
                    >
                        Forgot password?
                    </Header>
                    <Header
                        as='a'
                        href={getPrivacyPolicyUrl}
                        className='login-link'
                    >
                        Privacy Policy
                    </Header>
                </Grid.Column>
                <Grid.Column floated='right' width={12} className="container-login-button">
                    <Button
                        icon
                        labelPosition='right'
                        style={{
                            width: '196px',
                            height: '50px',
                            borderRadius: '35px',
                            float: 'right',
                            fontSize: '16px',
                            fontWeight: '500',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: '1.13',
                            letterSpacing: 'normal',
                            backgroundColor: 'var(--principal-color-page)',
                            color: '#FFFFFF',
                            paddingTop: '10px'
                        }}
                    >
                        Sign in
                        <Icon
                            name='play'
                            size='small'
                            style={{
                                backgroundColor: 'transparent',
                                right: '24px',
                                color: '#FFFFFF'
                            }}
                        />
                    </Button>
                </Grid.Column>
            </Grid>
        </Form>
    );
};

export default FormDataLogin;
