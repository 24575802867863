import React from 'react';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import {googleMapsApiKey} from "../../constants/secret";


const GoogleMap = props => {
    const {
        markerPosition,
        setMarkerPosition = null,
        height,
        onMapReady,
        onDragEnd = null,
        draggable = true,
        zoomControl = false
    } = props;

    const style = {
        height,
        position: 'relative',
    };

    const handleDragEnd = event => {
        // If the setMarkerPosition is defined, call the setMarkerPosition callback
        if (setMarkerPosition) {
            setMarkerPosition({lat: event.latLng.lat(), lng: event.latLng.lng()});
        }
        // If the onDragEnd is defined, call the onDragEnd callback
        if (onDragEnd) {
            onDragEnd(event.latLng);
        }
    };

    // A callback that takes a mapProp and a map as arguments
    const handleMapReady = (mapProps, map) => {
        // Read Google from mapProps
        const {google} = mapProps;
        // If the onMapReady is defined, call the onMapReady callback
        if (onMapReady) {
            onMapReady(google, map);
        }
    }

    return (
        <Map google={props.google}
             containerStyle={style}
             zoom={15}
             zoomControl={zoomControl}
             fullscreenControl={false}
             mapTypeControl={false}
             streetViewControl={false}
             draggable={false}
             initialCenter={{lat: markerPosition.lat, lng: markerPosition.lng}}
             onReady={handleMapReady}
             onClick={(_mapProps, _map, coordinates) => handleDragEnd(coordinates)}
        >
            <Marker position={{lat: markerPosition.lat, lng: markerPosition.lng}} draggable={draggable}
                    onDragend={(t, map, coord) => handleDragEnd(coord)}
            />
        </Map>
    );
};

export default GoogleApiWrapper({
    apiKey: (googleMapsApiKey),
})(GoogleMap);