import React from "react";
import {Message} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {dismissMessage} from "../redux/appDucks";

export const AppMessage = () => {
    const dispatch = useDispatch();

    const message = useSelector(state => state.app.message);

    const handleDismiss = () => {
        dispatch(dismissMessage());
    };

    const shouldDisplayMessage = () => {
        return message?.visible && message.header && message.body && message.type;
    };

    return <>
        {
            shouldDisplayMessage() && <Message
                compact
                floating
                onDismiss={handleDismiss}
                info={message.type === 'info'}
                success={message.type === 'success'}
                error={message.type === 'error'}
                warning={message.type === 'warning'}
                style={{
                    position: 'fixed',
                    top: '64px',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999
                }}
            >
                <Message.Header>{message.header}</Message.Header>
                <p>{message.body}</p>
            </Message>
        }
    </>
}
