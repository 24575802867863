import React from "react";
import {Label} from "semantic-ui-react";
import {updateLocalExperienceValue} from "../../../../redux/experienceDucks";
import {useDispatch, useSelector} from "react-redux";

export const ExperienceFormFeatures = () => {
    // A dispatch for updating the experience in the store.
    const dispatch = useDispatch();

    // A selector for retrieving the features of the experience in the store.
    const features = useSelector(state => state.experiences.experience.value.features);

    // A callback function that is called when a feature is toggled.
    // It toggles the feature's value in the experience object.
    const handleFeatureToggle = async (key) => {
        // If the key is not a property of the experience features, then log an error.
        if (!features.value.hasOwnProperty(key)) {
            console.error(`Feature '${key}' not found`);
            return;
        }
        // Compute the new experience features.
        const value = await features.value[key].setValue(!features.value[key].value);
        const patch = await features.setValue({
            ...features.value,
            [key]: value
        });
        // Toggle the feature's value in the experience features.
        dispatch(updateLocalExperienceValue({features: patch}));
    }

    return <>
        {
            Object.getOwnPropertyNames(features.value).map((name, index) => {
                return (<Label
                        key={index}
                        color={features.value[name].value ? 'teal' : 'grey'}
                        onClick={handleFeatureToggle.bind(this, name)}
                        className={'experience-form-feature-label'}
                    >
                        {features.value[name].label}
                    </Label>
                )
            })
        }
    </>;
}
