import {getAxios, putAxios} from "../services/Axios";
import {getProfileUrl, putProfileUrl} from '../constants/url';
import {handleError} from "./appDucks";

const initialState = {
    profile: {},
    loading: false,
};

const PROFILE_START_LOADING = 'PROFILE_START_LOADING';
const PROFILE_STOP_LOADING = 'PROFILE_STOP_LOADING';
const PROFILE_UPDATE_PROFILE = 'PROFILE_UPDATE_PROFILE';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PROFILE_START_LOADING:
            return {...state, loading: true};
        case PROFILE_STOP_LOADING:
            return {...state, loading: false};
        case PROFILE_UPDATE_PROFILE:
            return {...state, profile: action.payload};
        default:
            return state;
    }
};

export const getProfile = () => async (dispatch) => {
    dispatch({type: PROFILE_START_LOADING});
    try {
        const profileResult = await getAxios(getProfileUrl);
        dispatch({
            type: PROFILE_UPDATE_PROFILE,
            payload: fromMessageToUserProfile(profileResult.data)
        });
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch({type: PROFILE_STOP_LOADING});
    }
};

export const updateProfile = (profileData) => async (dispatch) => {
    dispatch({type: PROFILE_START_LOADING});
    try {
        const request = fromUserProfileToMessage(profileData);
        const response = await putAxios(putProfileUrl, request);
        dispatch({
            type: PROFILE_UPDATE_PROFILE,
            payload: fromMessageToUserProfile(response.data)
        });
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch({type: PROFILE_STOP_LOADING});
    }
};

const fromMessageToUserProfile = (data) => {
    return {
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phoneNumber: data.phone_number ?? '',
        avatar: data.avatar,
    };
};

const fromUserProfileToMessage = (data) => {
    const message = {
        'first_name': data.firstName,
        'last_name': data.lastName,
        'phone_number': data.phoneNumber,
    };
    if (data.avatarBase64) {
        message.avatar = data.avatarBase64;
    }
    return message;
};
